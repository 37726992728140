<!--
File: Roads.vue
Description: show list of roads entered in the DB.
-->
<template>
  <base-data-table v-if="eligible" :loading="showSpinner" :pagination="pagination" :from="from" :to="to" :total="total"
    :btnAddAllowed="isBtnAllowed('AddButton')" :showCloseBtn="!!closePath" @add-item="viewItem()">

    <template #header-filters>
      <road-classes-dropdown class="md-layout-item md-size-20" v-model="selectedRoadClass" @input='reloadData' />
      <search-input class="md-layout-item  md-size-20" v-model="searchQuery" />
    </template>

    <template #table-rows="{ item }">
      <base-table :items="queriedData" :headers="headers" :idFieldName="idFieldName" :sort="currentSort"
        :sortOrder="currentSortOrder">

        <template #table-actions="{ item }">
          <table-actions :btnEditAllowed="isBtnAllowed('EditButton')" :btnDeleteAllowed="isBtnAllowed('DeleteButton')"
            @edit="viewItem(item[idFieldName])" @delete="deleteItem(item[idFieldName], item.road_description)" />
        </template>

      </base-table>
    </template>

    <template #editing-dialog>
      <EditForm v-if="showDetailsDlg" :roadId="currentId" @close="showDetailsDlg = false" />
    </template>
  </base-data-table>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { RoadClassesDropdown, BaseTable, BaseDataTable, SearchInput, TableActions } from "@/pages/Components"
import BaseTableMixin from '@/mixins/BaseTableMixin'
import EditForm from './RoadEditForm.vue'

export default {
  name: 'roads-list',
  mixins: [BaseTableMixin],

  data() {
    return {
      formName: 'Roads',
      idFieldName: 'road_id',
      propsToSearch: ['road_description', 'road_class', 'road_number', 'length_m'],
      currentSort: 'road_number',

      selectedRoadClass: null,
      selectedRoad: null, // for showing sections belonging to the road

      historyMapping: {
        selectedRoadClass: null,
        currentSort: 'road_number',
        currentSortOrder: 'asc',
        pagination: {},
        searchQuery: ''
      }
    }
  },

  components: {
    BaseDataTable,
    BaseTable,
    SearchInput,
    TableActions,
    RoadClassesDropdown,
    EditForm,
  },

  methods: {
    ...mapActions({
      clearList: 'CLEAR_ROAD_LIST', // Called from the BaseTableMixin's mount()
      loadList: 'LOAD_ROAD_LIST',
      delete: 'DELETE_ROAD',
    }),

    handleSectionsClick(item) {
      this.selectedRoad = item;
      this.saveHistory2(['selectedRoad'], 'Sections');
      this.$router.push('/inventory_data/sections');
    },
  },

  computed: {
    ...mapState({
      tableData: (state) => state.RoadNetwork.road_list,
    }),

    ...mapGetters(['closePath']),

    headers() {
      return [
        { label: "road_network.road_class", value: "road_class", sortable: true },
        { label: "road_network.road_number", value: "road_number", sortable: true },
        { label: "stdCols.name", value: "road_description", sortable: true },
        { label: "road_network.length", value: "length_m", sortable: true, format: "number" },
        {
          label: "road_network.section_count", value: "section_count", sortable: true, condition: "item.section_count > 0",
          onClick: this.handleSectionsClick, clickField: 'road_id', format: "number"
        },
      ]
    }
  },

  watch: {
    selectedRoadClass(newVal) {
      this.payload = { road_class: newVal };
      this.reloadData();
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/mixins/BaseTable.scss';
</style>