<!--
File: RoadEditForm.vue
Description: form for adding/editing a single road.
-->
<template>
  <modal v-if="eligible" :title="screenTitle" :form="formName" :errCount="errors.count()"
    :hasUnsavedChanges="hasUnsavedChanges" @close="$emit('close')" @save="validate">

    <template slot='body'>
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-50">
          <RoadClassesDropdown :class="getClass('road_class')" v-model="road_class" data-vv-name="road_class"
            v-validate="modelValidations.road_class" :isRequired="true" required />
        </div>

        <div class="md-layout-item md-small-size-100 md-size-50">
          <md-field :class="getClass('road_number')">
            <label for="road_number">{{ $t('road_network.road_number') }}</label>
            <md-input id="road_number" v-model="road_number" type="number" data-vv-name="road_number" required
              v-validate="modelValidations.road_number" />
          </md-field>
        </div>
      </div>

      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-50">
          <md-field :class="getClass('road_key')">
            <label for="road_key">{{ $t('road_network.road_key') }}</label>
            <md-input id="road_key" v-model="road_key" type="text" data-vv-name="road_key" required
              v-validate="modelValidations.road_key" />
          </md-field>
        </div>

        <div class="md-layout-item md-small-size-100 md-size-50">
          <md-field :class="getClass('road_length')">
            <label for="road_length">{{ $t('road_network.length') }}</label>
            <md-input id="road_length" v-model="road_length" type="number" data-vv-name="road_length" required
              v-validate="modelValidations.road_length" />
          </md-field>
        </div>
      </div>

      <div class="md-layout-item md-small-size-100 md-size-100">
        <md-field :class="getClass('road_description_ru')">
          <label for="road_description_ru">{{ $t('stdCols.name_ru') }}</label>
          <md-input id="road_description_ru" v-model="road_description_ru" type="text"
            data-vv-name="road_description_ru" required v-validate="modelValidations.road_description_ru" />
        </md-field>
        <md-field :class="getClass('road_description_en')">
          <label for="road_description_en">{{ $t('stdCols.name_en') }}</label>
          <md-input id="road_description_en" v-model="road_description_en" type="text"
            data-vv-name="road_description_en" required v-validate="modelValidations.road_description_en" />
        </md-field>
      </div>
    </template>
  </modal>
</template>
<script>
import { mapActions } from 'vuex'
import { Modal, RoadClassesDropdown } from '@/pages/Components'
import { savedMessage } from '@/mixins/messagesMixin'
import permissions from "@/mixins/permissionsMixin"

export default {
  name: 'road-edit-form',
  mixins: [permissions],

  data() {
    return {
      formName: 'RoadEditForm',
      eligible: false,

      road_class: '',
      road_number: null,
      road_key: null,
      road_description_en: null,
      road_description_ru: null,
      // road_description_kg: null,
      road_length: null,

      initialFormState: null,
      modelValidations: {
        road_class: { required: true, min: 1 },
        road_number: { required: true, numeric: true, min_value: 1 },
        road_key: { required: true, min: 3 },
        road_description_en: { required: true, min: 3 },
        road_description_ru: { required: true, min: 3 },
        // road_description_kg: { required: true, min: 3 }
        road_length: { required: true, numeric: true, min_value: 1 },
      }
    }
  },

  props: {
    roadId: null
  },

  components: {
    Modal,
    RoadClassesDropdown
  },

  async mounted() {
    // Check if we are eligible to view the form
    this.eligible = await this.checkIfScreenAllowed();
    if (!this.eligible) {
      this.$emit('close');
      return;
    };

    if (this.roadId) { // Load data on existing road
      const theRoad = await this.loadRoad(this.roadId);
      this.road_key = theRoad.road_key;
      this.road_class = theRoad.road_class;
      this.road_number = theRoad.road_number;
      this.road_length = theRoad.length_m;
      this.road_description_en = theRoad.road_description_en;
      this.road_description_ru = theRoad.road_description;
    };
    this.$nextTick(async () => {
      this.initialFormState = this.getCurrentState;
      await this.$validator.validateAll();
    });
  },

  methods: {
    ...mapActions({
      loadRoad: 'LOAD_ROAD_BY_ID',
      addItem: 'ADD_NEW_ROAD',
      editItem: 'UPDATE_ROAD',
      highlightRow: 'HIGHLIGHT_ROW'
    }),

    async validate() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) return;

      const theItem = this.getCurrentState;
      let errDesc = '';
      let newroadId;
      const action = !this.roadId ? this.addItem : this.editItem;
      const payload = !this.roadId ? theItem : { id: this.roadId, theItem };
      try {
        const res = await action(payload);
        newroadId = res?.road_id;
      } catch (err) {
        errDesc = err.message || this.$t('messages.unknown_error');
      };

      this.$nextTick(() => this.$validator.reset());
      this.$emit('close');
      await savedMessage(errDesc, this.$t('road_network.road'), this.road_description_ru, this.road_description_en);
      this.highlightRow(newroadId);
    }
  },

  computed: {
    getCurrentState() {
      return {
        fk_rn: 1,
        road_class: this.road_class,
        road_number: Number(this.road_number),
        length_m: Number(this.road_length),
        road_key: this.road_key,
        road_description_en: this.road_description_en,
        road_description: this.road_description_ru,
        //road_description_kg: this.road_description_kg
      }
    },

    screenTitle() {
      return this.roadId ? this.$t('screen_titles.road_upd') : this.$t('screen_titles.road_add')
    },

    hasUnsavedChanges() {
      if (!this.initialFormState) return false
      return this.initialFormState.road_class !== this.road_class ||
        this.initialFormState.road_number !== Number(this.road_number) ||
        this.initialFormState.road_length !== Number(this.road_length) ||
        this.initialFormState.road_key !== this.road_key ||
        this.initialFormState.road_description_en !== this.road_description_en ||
        this.initialFormState.road_description !== this.road_description //||
      //this.initialFormState.road_description_kg !== this.road_description_kg
    }
  }
}
</script>